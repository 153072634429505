body {
  background-color: #E2D5D5;
  font-family: 'Roboto', serif;

  touch-action: none;
  -webkit-text-size-adjust: none;

  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
         -o-user-select: none;
            user-select: none;
}
